.set-access-body {
    background-color: white;
}
.set-access-body .selection-data {
    height: 56px;
    border-radius: 4px;
    border-left: 5px solid #0973ba;
    background-color: #e6f1f8;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
}

.set-access-body .selection-data span {
    margin-left: 0.5rem;
}
.set-access-body .selection-data span:first-child {
    margin-top: -4px;
}
.set-access-body .usersDnD {
    padding: 1rem 0 0 0;
}
.set-access-body .userGroupsDnD {
    padding: 1rem 0;
}
.set-access-body .userGroupsDnD :global .each-group-set-access {
    display: inline-block;
    align-items: center;
    height: 35px;
    width: auto;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    padding: 0px 8px;
    font-weight: 400;
    font-size: 8px;
}
.set-access-body .errorMessage {
    color: #cc4a43;
    font-size: 14px;
    font-weight: 400;
    margin-left: 406px;
    margin-top: -9px;
}
.set-access-footer {
    background-color: white;
}
.set-access-footer div {
    display: flex;
    justify-content: flex-end;
}
.set-access-footer div .reset-button {
    border: none;
    background-color: #fff;
    margin-right: 1rem;
    cursor: pointer;
}
.set-access-footer div .reset-button .resetText {
    display: inline-block;
    cursor: pointer;
    color: #0973ba;
    text-decoration: underline;
    font-size: 1.75rem;
    font-weight: 400;
}
.set-access-footer div .cancel-button {
    width: 100px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 400;
    background-color: #f0f1f1!important;
    border: 1px solid #898d91;
    border-radius: 2px !important;
    color: #212529!important;
    padding: 6px 12px 6px 12px;
    cursor: pointer;
    box-shadow: none;
}
.set-access-footer div .save-button {
    width: 100px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 400;
    background-color: #0973ba;
    border: 1px solid #0973ba;
    border-radius: 2px !important;
    color: #fff;
    padding: 6px 12px 6px 12px;
    cursor: pointer;
    box-shadow: none;
}

.closeIcon {
    color: rgba(0, 0, 0, 0.5);
    width: 26px;
    line-height: 24px;
    cursor: pointer;
}
