.drag-and-drop-container {
	 display: flex;
	 justify-content: space-between;
	 position: relative;
}
 .drag-and-drop-container article {
	 width: 350px;
}
.data-header {
	 font-family: 'Mulish';
	 font-weight: 700;
	 font-size: 12px;
	 color: #212529;
	 margin-right: 6px;
}
 .drag-and-drop-container article span svg {
	 margin-top: -3px;
	 cursor: pointer;
}
 .drag-and-drop-container article ul::-webkit-scrollbar {
	 width: 0.6rem;
}
 .drag-and-drop-container article ul::-webkit-scrollbar-thumb {
	 background-color: #0973ba86;
	 border-radius: 3px;
}
 .drag-and-drop-container article ul::-webkit-scrollbar-thumb:hover {
	 background-color: #0973ba;
	 border-radius: 3px;
}
 .drag-and-drop-container article ul {
	 padding: 0px;
	 border: 1px solid #a6a9ac;
	 height: 331px;
	 overflow-y: auto;
	 margin: 0.6rem 0 0 0;
	 scrollbar-width: thin;
}
 .drag-and-drop-container .reset-button {
	 position: absolute;
	 right: 0px;
	 top: -4px;
	 display: flex;
	 align-items: center;
}
 .drag-and-drop-container .reset-button .reset-text {
	 font-size: 1rem;
	 font-weight: 400;
	 color: #0973ba;
	 margin-left: 0.5rem;
	 text-decoration: underline;
}
 