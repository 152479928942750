.location-mismatch-modal .modal-dialog {
    display: flex;
    width: 550px;
    height: 266px;
    border-radius: 4.8000001907px;
    border: 1px;
}
.location-mismatch-modal .modal-dialog .modal-content {
    border-radius: 4.8px;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
}
.location-mismatch-modal .modal-dialog .modal-content .modal-header {
    background-color: #fff;
    color: #212529;
    border-bottom: 1px solid #dee2e6;
    line-height: 24px;
    font-size: 20px;
    padding: 9px 1rem;
    border-top-left-radius: 4.8px;
    border-top-right-radius: 4.8px;
}
.location-mismatch-modal .modal-dialog .modal-content .modal-header .closeIcon {
    color: rgba(0, 0, 0, 0.5);
    width: 26px;
    line-height: 24px;
    cursor: pointer;
}
.location-mismatch-modal .modal-dialog .modal-content .modal-header .modal-title {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #212529;
}
.location-mismatch-modal .modal-dialog .modal-content .modal-body {
    height: fit-content;
    background-color: #fff;
    font-size: 14px;
    padding-bottom: 0px !important;
}
.location-mismatch-modal .modal-dialog .modal-content .modal-body .warning-alter {
    line-height: 18px;
    padding: 0.5rem 0;
}
.location-mismatch-modal .modal-dialog .modal-content .modal-body .warning-alter p {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px 0px 0px 0.5rem;
    color: #564016;
    width: 490px;
}
.location-mismatch-modal .modal-dialog .modal-content .modal-footer {
    background: #fff;
}
.location-mismatch-modal .modal-dialog .modal-content .modal-footer button {
    width: 100px;
    box-shadow: none;
}
.location-mismatch-modal .modal-dialog .modal-content .modal-footer .btn-secondary {
    height: 38px;
    padding: 6px 12px 6px 12px !important;
    border-radius: 2px !important;
    gap: 8px;
    border: 1px solid #898d91 !important;
    background: #f0f1f1;
    color: #212529;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
}
.location-mismatch-modal .modal-dialog .modal-content .modal-footer .btn-primary {
    height: 38px;
    padding: 6px 12px 6px 12px !important;
    border-radius: 2px !important;
    gap: 8px;
    border: 1px solid #0973ba !important;
    background: #0973ba;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
}

