/* Left Panel style starts here */
.bookmarksPane .card button,
.accordion .card button {
    border: none;
}

.bookmarksPane .card .card-title,
.accordion .card .card-header,
.accordion .card .card-header .card-title {
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
    margin: 0;
}

.bookmarksPane .accordion .card .card-header {
    display: flex;
    justify-content: space-between;
}

.bookmarksPane .accordion .card .card-header .float-right {
    display: inline-block;
    margin-top: -3px;
}

.card-header {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}

/* Right Panel style starts here */
/*Right Panel style starts here*/
.left-panel,
.right-panel {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.awesome-pdf-viewer .left-panel,
.right-panel {
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
}

.awesome-pdf-viewer .right-panel {
    min-width: 350px;
}

.awesome-pdf-viewer .left-panel {
    min-width: 225px;
}

.awesome-pdf-viewer .document-control span {
    line-height: initial;
    margin: 0%;
    margin-left: 0px;
}

.bookmarksPane {
    overflow-y: auto;
    overflow-x: hidden;
}

.bookmarksPane .panel,
.right-panel .panel {
    border-radius: 0px !important;
    margin-bottom: 5px !important;
}

.right-panel .panel-title {
    cursor: pointer;
}

.bookmarksPane .panel-default .panel-heading,
.right-panel .panel-default .panel-heading {
    color: #fff;
    background-color: #c0bfcf;
    border-color: #ddd;
    border-radius: 0px !important;
    background-color: #EEEEEE;
    color: #696969;
}

.right-panel .panel-title i {
    color: #808080;
    margin-right: 10px;
    text-shadow: 1px 1px 1px white;
}

.awesome-pdf-viewer .move-to-dropdown-wrapper {
    display: inline-block;
    margin-left: 10px;
}

.awesome-pdf-viewer .middle-section {
    display: flex;
    align-items: center;
}

.awesome-pdf-viewer .toolbar-item-select {
    height: 26px !important;
}

.custom-pagination-container .search-field {
    border: 1px solid rgba(118, 118, 118, 0.3);
    border-radius: 2px;
}