:root {
    --input-border-color: #898D91;
    --primary-blue: #0973BA;
}

.client-data-difference {
    color: #cc4a43 !important;
    font-weight: 600;
}

.mismatch-modal .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: calc(100% - 3.5rem);
    margin: 1.75rem auto;
    width: 100%;
    height: 100%;
}

.mismatch-modal .modal-dialog .modal-content {
    border-radius: 4.8px;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
    width: 700px;
    height: auto;
    box-shadow: none;
}

.mismatch-modal .modal-header {
    background: #ffffff;
    border-bottom: 1px solid #DEE2E6;
    line-height: 24px;
    font-size: 20px;
    padding: 9px 1rem;
    border-top-left-radius: 4.8px;
    border-top-right-radius: 4.8px;
}

.mismatch-modal .closeIcon {
    fill: #808080;
    width: 26px;
    line-height: 24px;
    cursor: pointer;
}

.mismatch-modal .modal-title {
    line-height: 120%;
    font-size: 20px !important;
    color: #212529 !important;
    font-weight: 700 !important;
    font-family: 'Mulish', sans-serif !important;
}

.mismatch-modal .modal-body {
    font-size: 16px;
    line-height: 150%;
    background: #fff;
    overflow-y: auto;
    max-height: 400px;
}

.white-background {
    background: #fff;
}

.mismatch-modal .modal-dialog .modal-content .modal-footer .btn-secondary {
    background: #F0F1F1;
    border: 1px solid var(--input-border-color);
    border-radius: 2px !important;
    color: #212529;
    font-size: 16px;
    line-height: 150%;
    font-family: 'Mulish', sans-serif !important;
}

.mismatch-modal .modal-dialog .modal-content .modal-footer .btn-primary {
    background: var(--primary-blue);
    border: 1px solid var(--primary-blue);
    border-radius: 2px !important;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 150%;
    font-family: 'Mulish', sans-serif !important;
}

.mismatch-modal .do-not-update-button {
    padding: 6px 9px;
}

.mismatch-modal .update-button {
    padding: 6px 35px;
}

.clients-list-table {
    table-layout: fixed;
    color: #212529;
    font-size: max(min(2.1vw, 1rem), 12px);
    line-height: 1rem;
    border-spacing: 0px;
    margin: 15px 0 0 0;
    border-top: 0;
}

.clients-list-table thead {
    border-bottom: 2px solid #212529;
}

.clients-list-table th {
    color: #212529;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    background-color: #fff;
    padding-left: 0;
    padding-right: 0;
    border-top: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.clients-list-table th:nth-child(2) {
    text-align: center;
}

.clients-list-table th:last-child {
    background-color: #E6EBF080;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.clients-list-table th.widthAuto {
    width: 100%;
}

.clients-list-table th.width70 {
    width: 70%;
}



.clients-list-table tbody tr.client-row td {
    font-size: 16px;
    line-height: 150%;
    vertical-align: middle;
    background-color: #fff;
    padding: 3.5px 10px 3.5px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-top: 1px solid #A6A9AC;
}

.clients-list-table tbody tr.client-row td:first-child {
    color: #6B7075;
}

.clients-list-table tbody tr.client-row td:nth-child(2) {
    text-align: center;
    padding: 0 10px;
}

.clients-list-table tbody tr.client-row td:last-child {
    background-color: #E6EBF080;
    text-align: center;
    padding: 0 10px;
}

.clients-list-table tbody tr.client-row td.width-auto,
.clients-list-table tbody tr.client-row td.width9,
.clients-list-table tbody tr.client-row td.width10,
.clients-list-table tbody tr.client-row td.width20 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.clients-list-table tbody tr.client-row td.actions-col-data {
    font-size: max(min(1.9vw, 1rem), 12px);
    padding: 4px 12px 3px 4px;
}

.prefilled-field,
.prefilled-field .Select-value 
{
    background-color: #E6F1F8 !important; 
}