.restrict-access-text,
.restrict-page-body{
    color: rgb(33,37,41);
    font-size: 12px;
    font-family: Mulish, sans-serif;
    font-weight: 400;
    text-align: start;
    padding: 0px 0px 0px 0px;    
    line-height: 18px;
    display: block;
    box-sizing: border-box;
}

.restrict-access-text{
    margin: 0px 0px 15px 0px;
}

.restrict-page-body{
    /* top|right\bottom\left*/
    margin: 0px 0px 0px 10px;
}

.restrict-access-text h1,
.restrict-access-text h4{
    font-family: Mulish, sans-serif;
    font-weight: bolder;
    margin-block-start: 6px;
    margin-block-end: 6px;
    margin: 6px 0px 6px 0px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;  
    text-align: start;  
}

.restrict-access-text h1{
    line-height: 28.8px;
    font-size: 24px;
}

.restrict-access-text h4{
    font-size: 12px;
    line-height: 14.4px;    
}

.login-link{
    box-sizing: border-box;
    color: rgb(51,122,183);
    cursor: pointer;
    font-family: Mulish, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-decoration: rgb(51, 122, 183);
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: auto;
}