


body {
    overflow: hidden !important;
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    /*margin-top: 62px;*/
}


.react-datepicker {
    font-size: 1.3rem !important;
}

.react-datepicker__current-month {
    font-size: 1.5rem !important;
}

.react-datepicker__header {
    padding-top: 6px !important;
}

.react-datepicker__navigation {
    top: 13px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem !important;
}

.dropup,
.dropright,
.layout-dropdown,
.dropleft {
    position: relative;
}

.layout-dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.layout-dropdown-toggle:empty::after {
    margin-left: 0;
}

.layout-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.layout-dropdown-menu-right {
    left: auto;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    font-size: 14px;
}

    .layout-dropdown-menu-right span {
        margin: 10px;
        color: black;
        font-size:13px;
    }

    .layout-dropdown-menu-right i {
        color: #555464 !important;
        text-shadow: #ffffff 1px 1px 1px;
        font-size: 13px;
    }
    .layout-dropdown-menu-right li {
        padding:2px;
    }

    .layout-dropdown-menu-right li {
        padding: 2px;
    }

.app-header-menu {
    height: 48px;
}

    .app-header-menu .app-header-menu-item {
        padding: 15px;
        cursor: pointer;
        color: black;
    }

        .app-header-menu .app-header-menu-item i {
            color: #555464 !important;
            text-shadow: #ffffff 1px 1px 1px;
        }

        .app-header-menu .app-header-menu-item:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }      



    .app-header-menu .dropdown-toggle {
        font-size: 15px;
    }

.dropdown-toggle:not(#move-to-dropdown):after {
    border: none;
}

#move-up-link:hover, #move-down-link:hover {
    text-decoration: underline;
}

.ml-auto {
    float: right;
}


@media (min-width: 768px) {
    
    .modal-dialog {
        max-width: 900px;
    }

    .edit-adddoc-modal-dialog .modal-dialog {
        max-width: 600px;
        font-size: 14px !important;
    }
}

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 1.75rem 2rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: white;
    border-radius: 0px !important;
    border-bottom: 1px solid #e8e8e8;
    height: 50px;
    font-size: 1.25rem;
    position: fixed;
    width: 100%;
    z-index: 10;
    box-shadow: 0 0 8px #dcdcdc;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}

    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #6c757d;
        content: "/";
    }

    .breadcrumb-item + .breadcrumb-item:hover::before {
        text-decoration: underline;
    }

    .breadcrumb-item + .breadcrumb-item:hover::before {
        text-decoration: none;
    }

.breadcrumb-item.active {
    color: #6c757d;
}

.breadcrumb-item a {
    color: teal;
}

#wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex: 1;
}

    #wrapper #content-wrapper {
        overflow-x: hidden;
        width: 100%;
        overflow: auto;
    }

.bottom-menu-title-padding {
    padding-left: 10px
}

footer.sticky-footer {
    position: absolute;
    right: 0;
    bottom: 0px;
    height: 20px;
    background-color: rgb(241, 236, 236);
    width: 100%;
    font-size: 11px;
    padding: 0px 7px 0px 7px;
}

    footer.sticky-footer .copyright {
        line-height: 1;
        font-size: 0.8rem;
    }

.finish-list {
    background-color: white;
    width: 100%
}

li.active.finish-list {
    width: 101%;
    border-left: 1px solid #eee;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.nav-item > .nav-link:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: white;
}

.nav-item button {
    padding-right: 10px !important;
    font-size: 14px;
    line-height: .5;
}

.layout-dropdown-menu .layout-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.50rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}

    .layout-dropdown-menu .layout-dropdown-item:hover {
        background-color: rgba(0, 0, 0, 0.05);
        text-decoration: none;
    }

.form-control, .react-bs-table-bordered, .btn, .Select-control {
    border-radius: 0px !important;
}

    .btn i, .btn-xs, .btn-group-xs > .btn {
        border-radius: 0px !important;
    }

.pagination > li:first-child > a, .pagination > li:first-child > span,
.pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: 0px !important;
}

.table-hover > tbody > tr:hover table-hover > tbody > tr {
    background-color: #edf9ff;
}

.table-hover > tbody > tr:hover {
    background-color: #edf9ff;
    cursor: pointer
}

.row-selected {
    background-color: #caedff !important;
}

label, table {
    font-size: 12px;
}

.nav-tabs.nav-justified > li > a {
    border-radius: 0px !important;
}

.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus {
    border-top: 4px solid #0af;
}

.bootstrap-grid-no-padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.bootstrap-grid-sm-padding {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.bootstrap-grid-md-padding {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

.tag-label {
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-right: 5px;
    font-size: smaller;
}

.tab-content-container {
    font-size: 14px;
}

.tab-content-container-left-panel {
}

.tab-content-container-middle-panel {
    padding: 15px 15px 0px 15px;
    background-color: grey;
    text-align: center;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
}

    .tab-content-container-middle-panel .pr-center-pannel .btn-toolbar .dropdown .dropdown-toggle::after {
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }


.tab-content-container-middle-panel-no-content {
    padding: 15%;
    text-align: center;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
}


.tab-content-container-right-panel {
}

.tab-no-content-message {
    color: white;
}


/* ===== Begin Procee Return Modal ===== */
.contentClassName {
    top: 0px !important;
}
/* Need to remove the header*/

.contentClassName {
    top: 0px !important;
}
/* Need to remove the header*/
.custom-window {
    position: relative;
    height: 100%;
}

    .custom-window .custom-window-header {
        background: rgb(241, 236, 236);
        padding: 10px 15px;
        height: 50px;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        line-height: 30px;
    }

        .custom-window .custom-window-header .header-inner {
            position: relative;
            padding-right: 130px;
            padding-left: 35px;
        }

            .custom-window .custom-window-header .header-inner .fa-rocket {
                color: gray;
                font-size: 22px;
                position: absolute;
                left: 0px;
                top: 4px;
            }

            .custom-window .custom-window-header .header-inner .title {
                color: #1a8fbf;
                font-size: 16px;
                font-weight: bold;
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            }

            .custom-window .custom-window-header .header-inner .header-btn {
                position: absolute;
                top: 0px;
                right: 0px;
            }

                .custom-window .custom-window-header .header-inner .header-btn button {
                    width: 24px;
                    height: 24px;
                    font-size: 14px;
                    margin-left: 2px;
                    background: transparent;
                    border: 1px solid transparent;
                    color: #000000;
                    opacity: .4;
                    line-height: 24px;
                }

                    .custom-window .custom-window-header .header-inner .header-btn button:hover {
                        border: 1px solid #C1C1C1;
                        opacity: .6;
                    }

    .custom-window .custom-window-content {
        padding: 65px 15px 85px;
        height: 100%;
    }

        .custom-window .custom-window-content .nav-tabs {
            margin-bottom: 10px;
            font-size: 14px;
        }

        .custom-window .tab-content .tab-pane, .custom-window .tab-content .tab-pane .h100 {
            height: 100%;
        }

    .custom-window .custom-window-content .pr-right-pannel, .custom-window .custom-window-content .pr-left-pannel, .custom-window .custom-window-content .pr-center-pannel {
        position: relative;
        height: 100%;
    }

    .custom-window .custom-window-content .scroll {
        overflow-y: auto;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
    }

    .custom-window .custom-window-content .box {
        height: 320px;
        display: block;
        background: #CCC;
        margin-bottom: 10px;
    }

    .custom-window .custom-window-footer {
        height: 70px;
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 15px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: white;
    }

        .custom-window .custom-window-footer .btn + .btn {
            margin-left: 10px;
        }

#process-return-groups {
    height: 100%;
    position: relative;
}

.input-group.date > input[type=text] {
    z-index: 0 !important;
}

.multi-select-widthauto .Select-menu-outer {
    z-index: 999 !important;
    width: auto !important;
    font-size: 11px !important;
}
.multi-select-widthauto-office-location .Select-menu-outer>* {
    width:130px;
    overflow-x: hidden;
    font-size: 11px !important;
}

.multi-select-width100 .Select-menu-outer {
    width: 100% !important;
    font-size: 11px !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.svg-shadow {
    filter: drop-shadow(10px 10px 5px #DADADA);
}

.sort-column[title='Status'],
.sort-column[title='Tax Year'],
.sort-column[data-field='documentStatus'],
.sort-column[data-field='signatureStatus'],
.sort-column[data-field='lastReminder'],
.sort-column[data-field='downloadedDate'],
.sort-column[data-field='retentionPeriod'],
.sort-column[data-field='taxYear'],
.sort-column[data-field='status'],
.sort-column[data-field='sourceDocumentStatus'] {
    overflow: inherit !important;
}

.react-bs-container-header {
    overflow: inherit !important;
}

.Select-multi-value-wrapper span:not(:first-child) {
    display: none;
}

.textalign-right {
    text-align: right;
}
/*.upload-doc-modal .prosystem-upload-doc-body {
    overflow: auto;
}*/
/* SF */
.sf-container {
    width: 100%;
    margin-top: 8px;
}

.sf-progressbar {
    counter-reset: step;
}

    .sf-progressbar li {
        list-style: none;
        display: inline-block;
        width: 25%;
        position: relative;
        text-align: center;
        cursor: pointer;
        vertical-align: top;
    }

        .sf-progressbar li:before {
            content: counter(step);
            counter-increment: step;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #ddd;
            border-radius: 100%;
            display: block;
            text-align: center;
            margin: 0 auto 10px auto;
            background-color: #fff;
        }

        .sf-progressbar li:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #ddd;
            top: 15px;
            left: -50%;
            z-index: -1;
        }

        .sf-progressbar li:first-child:after {
            content: none;
        }

        .sf-progressbar li.sfstep-active:before {
            border-color: #1d9bff;
            background-color: #1d9bff;
            color: white;
        }


        .sf-progressbar li.sfstep-completed {
            color: rgb(0, 128, 0);
        }

            .sf-progressbar li.sfstep-completed:before {
                border-color: rgb(0, 128, 0);
                content: '\2713';
                background-color: rgb(0, 128, 0);
                color: white;
            }

            .sf-progressbar li.sfstep-completed + li:after {
                background-color: rgb(0, 128, 0);
            }

.panel-footer .btn + .btn {
    margin-left: 10px;
}

.sf-panel-header {
    background-color: white;
    position: sticky;
    top: 0px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: white;
    /* font-size: 3rem; */
    /* position: relative; */
    z-index: 10;
    height: 100px;
}

.sf-panel-footer {
    position: absolute;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: right;
    -ms-flex-align: center;
    align-items: right;
    -ms-flex-pack: center;
    justify-content: flex-end;
    background-color: white;
    background-color: white;
    text-align: right;
    margin: -1px;
}

.text-center-align {
    text-align: center !important;
}

.text-left-align {
    text-align: left !important;
}

#sfUploadDocumentTable .react-bs-table-container .react-bs-table .react-bs-container-body {
    overflow: inherit;
}

.sfPanel-body {
    position: relative;
    overflow-x: hidden;
    overflow: auto;
    height: calc(100vh - 230px);
}

.custom-sfPanel-body {
    position: relative;
    overflow-x: hidden;
    overflow: hidden;
    height: calc(100vh - 230px);
}


    .custom-sfPanel-body .tab-content-container {
        height: 100%;
    }

    .custom-sfPanel-body .tab-content-container-left-panel {
        height: 100%;
    }

    .custom-sfPanel-body .tab-content-container-middle-panel {
        height: 100%;
    }

    .custom-sfPanel-body .tab-content-container-right-panel {
        height: 100%;
    }

    .custom-sfPanel-body .tab-content-container .pr-right-pannel,
    .custom-sfPanel-body .tab-content-container .pr-left-pannel,
    .custom-sfPanel-body .tab-content-container .pr-center-pannel {
        position: relative;
        height: 100%;
    }

    .custom-sfPanel-body .scroll {
        overflow-y: auto;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
    }

    .custom-sfPanel-body .box {
        height: 320px;
        display: block;
        background: #CCC;
        margin-bottom: 10px;
    }

/*.accordian-panel .panel .panel-heading .panel-title .accordion-toggle:after {*/
/* symbol for "opening" panels */
/*font-family: 'Glyphicons Halflings';*/ /* essential for enabling glyphicon */
/*content: "\e114";*/ /* adjust as needed, taken from bootstrap.css */
/*float: right;*/ /* adjust as needed */
/*color: grey;*/ /* adjust as needed */
/*font-style: normal;
}

.accordian-panel .panel .panel-heading .panel-title .accordion-toggle.collapsed:after {*/
/* symbol for "collapsed" panels */
/*font-family: 'Glyphicons Halflings';*/ /* essential for enabling glyphicon */
/*content: "\e080";*/ /* adjust as needed, taken from bootstrap.css */
/*font-style: normal;
}*/

.accordian-panel .panel .panel-heading .panel-title .accordion-toggle.open:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings'; /* essential for enabling glyphicon */
    content: "\e114";
    color: grey;
    font-style: normal;
}

.accordian-panel .panel .panel-heading .panel-title .accordion-toggle.closed:after {
    /* symbol for "collapsed" panels */
    font-family: 'Glyphicons Halflings'; /* essential for enabling glyphicon */
    content: "\e080";
    font-style: normal;
}


.sf-receipient-signer-sign-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(242,227,12,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(242,227,12,1);
    box-shadow: 0px 0px 10px 5px rgba(242,227,12,1)
}

.sf-sender-signer-sign-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
    box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
}

.mar-B0 {
    margin-bottom: 0px !important;
}

.bookmarksPane .accordian-panel .panel {
    border-radius: 0px !important;
    margin-bottom: 0px !important;
}

#mailMergeUploadCsvTable {
    padding-top: 7%;
}

    #mailMergeUploadCsvTable .react-bs-table-container .react-bs-table .react-bs-container-body {
        overflow: inherit;
    }

    #mailMergeUploadCsvTable .react-bs-container-header.table-header-wrapper {
        display: none;
    }

.invalidMailMergeCellValue {
    color: red;
    cursor: pointer;
}

#div-validation-btn {
    height: 29vh;
    background-color: lightgrey;
    text-align: center;
    padding-top: 8vh;
}

    #div-validation-btn div {
        margin-top: 10px;
    }

.mailmerge-custom-field {
    border: none;
}

.mailmerge-custom-field-tag {
    background-color: #66afe930;
    border-radius: 5px;
    border: 1px solid #3791d8;
    color: black;
    display: inline-block;
    font-family: sans-serif;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px;
    cursor: pointer;
}

.mailmerge-custom-field-tag-remove {
    color: #274c96;
    padding-left: 5px;
    cursor: pointer;
}

.custom-field-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
    box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
}

#pdfpreview .modal-content {
    display: inline-block;
}

.csv-table-td-error {
    outline-color: red;
    outline-style: solid;
    outline-width: 3px;
    outline-offset: -1px;
}

.react-bs-table .table-bordered > tbody > tr > td.csv-table-td-error {
    outline: 3px solid red !important;
    outline-offset: -1px;
}

.react-bs-table .table-bordered > tbody > tr > td.csv-table-td-warning {
    outline: 3px solid orange !important;
    outline-offset: -1px;
}




.margin-tbl-20 {
    margin: 20px 0 20px 20px;
}

.margin-top-10 {
    margin-top: 10px;
}

.beforeContent {
    position: relative;
}

    .beforeContent::before {
        content: '-';
        position: absolute;
        left: -10px;
    }

@media screen and (min-width: 1360px) {
    #ddlUploadSFDocument .Select-arrow-zone {
        width: 15px;
    }
}

.fa-btn-icon {
    padding: 4px 0;
    text-shadow: 0px 1px 0px rgba(255,255,255,.5);
    font-size: 12px;
}

.extension-sender-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(242,227,12,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(242,227,12,1);
    box-shadow: 0px 0px 10px 5px rgba(242,227,12,1)
}

.extension-taxpayer-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
    box-shadow: 0px 0px 10px 5px rgba(247,241,126,1);
}

.extension-spouse-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
    box-shadow: 0px 0px 10px 5px rgba(209,196,8,1);
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu {
    position: fixed !IMPORTANT;
}

    .react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li {
        padding-top: 2px;
    }

        .react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        .react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li a {
            color: black;
            font-size: 14px;
            padding-right: 5px;
            text-decoration: none;
        }

.filters ul li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.app-header-menu-item ul li a {
    text-decoration: none;
}

.app-header-menu-item ul li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.page-item.active .page-link {
    background-color: #337ab7;
    border-color: #337ab7;
}

a {
    color: #337ab7;
}

.pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.edit-client-instruction-modal {
    font-size: 14px;
}

.preparer-message {
    font-size: 14px;
}

    .edit-controller-info .modal-content,
    .delivered-unlock-return .modal-content,
    .savefilter-modal .modal-content,
    .download-history-modal .modal-content,
    .columnsettings-modal .modal-content,
    .customcolumn-modal .modal-content,
    .preparer-message .modal-content,
    .reminder-update-modal .modal-content,
    .report-change-status-modal .modal-content,
    .rentention-modal .modal-content,
    .downloadreturn-modal .modal-content,
    .downloadefile-modal .modal-content,
    .resendaccess-modal .modal-content,
    .last-login .modal-content,
    .edit-client-info .modal-content,
    .my-account-modal .modal-content,
    .change-password-modal .modal-content,
    .logout-confirm-modal .modal-content,
    .resend-access-link-modal .modal-content,
    .edit-client-instruction-modal .modal-content,
    .session-timeout-modal .modal-content,
    .edit-column-value-modal .modal-content,
    .header-my-account-modal .modal-content  {
        width: 600px !important;
       
    }


.edit-client-info-modal .modal-content,
.edit-batch-info-modal .modal-content,
.download-extension-modal .modal-content {
    width: 700px !important;
}

label {
    max-width: 100%;
    font-weight: 700;
}

.report-problem-modal .form-group {
    width: 100%;
}

.session-timeout-modal .modal-body {
    font-size: 14px;
}

.dropdown-arrow .dropdown-toggle::after {
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.panel-body {
    padding: 15px;
}

.form-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 12px;
}

.badge {
    background-color: #777 !important;
    border-radius: 10px !important;
    color: #fff !important;
}

.badge-icon {
    font-size: 12px;
    float: right;
}

.page-carousel-modal .page-carousel-modal-content {
    background-color: transparent;
}

.page-carousel-modal-backdrop {
    background-color: #000;
    opacity: 0.8 !important;
}

.page-carousel-modal .page-carousel-modal-dialog {
    background-color: transparent;
    max-width: 100%;
}

.page-carousel-modal .page-carousel-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
}

.page-carousel-modal .page-carousel-modal-content .page-carousel-modal-overlay {
    position: relative;
    width: 100%;
}

.page-carousel-modal .bootstrap-carousel {
    position: relative;
    margin: 0 auto;
}

.page-carousel-modal .bootstrap-carousel .slide {
    text-align: center;
    background-color: transparent;
    transition: transform 0.4s ease-in-out !important;
}

.page-carousel-modal .carousel-next,
.carousel-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.2);
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 20px;
    color: white;
    z-index: 1;
}

.page-carousel-modal .bootstrap-carousel .slide .fas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.page-carousel-modal-overlay .carousel-next {
    right: 20px;
}

.page-carousel-modal-overlay .carousel-prev {
    left: 20px;
}

.page-carousel-modal-overlay-fullscreen,
.page-carousel-modal-overlay-close {
    position: absolute;
    top: 0;
    color: white;
    cursor: pointer;
    line-height: 1;
    padding: 20px;
}

.page-carousel-modal-overlay-fullscreen {
    right: 60px;
    font-size: 24px;
}

.page-carousel-modal-overlay-close {
    font-size: 28px;
    right: 20px;
    top: -2px;
}

.page-carousel-modal-overlay-name,
.page-carousel-modal-overlay-page-number {
    position: fixed;
    bottom: 0;
    padding: 20px;
    color: white;
}

.page-carousel-modal-overlay-name {
    left: 0;
}

.page-carousel-modal-overlay-page-number {
    right: 10px;
    left: auto;
}

.disable-panel {
    pointer-events: none;
    opacity: 0.5;
    background: #CCC;
}

.edit-document-value-modal {
    font-size: 14px;
    margin-left: 15px;
}

.delete-document-type-modal {
    font-size: 14px;
    margin-left: 5px;
}

.popover-title {
    font-size: 14px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 9px;
    padding-bottom: 9px;
}

.popover-content {
    padding-left: 8px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.calendarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%
}

.calendarInput {
    position: absolute;
    z-index: 1000;
    top: 30px;
}

.calendarClass {
    min-width: 0px;
    display: inline-block;
    position: relative;
    width: 100%
}

.inputClass {
    width: 100%;
    height: 30px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    font-size: 12px;
    padding-left: 6px
}

.minusClass {
    position: absolute;
    top: 0%;
    right: 0%;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    border-radius: 0px 3px 3px 0px;
    height: 100%;
    font-family: monospace
}

    .minusClass:hover {
        cursor: pointer;
    }

#dateId .dateClass .react-datepicker-container .react-datepicker-top {
    background: #ddd;
    color: black;
    border-color: #ddd;
}

#datepicker_input div {
    min-width: 0px;
}

    #datepicker_input div .react-datepicker {
        top: 100%;
    }

.tpLabelFont {
    font-size: 12px;
    font-weight: bold;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.react-bs-table .table-bordered > thead > tr:first-child > th {
    overflow: visible;
}

.react-bs-table .display-checkBox {
    padding: 0;
}

.btn-white:hover {
    background: #ebebeb;
}

.font700 {
    font-weight: 700;
}

.table-text-sort {
    display: inline-block;
    width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.download-return-pop-up {
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.justify-flex-end {
    justify-content: flex-end
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

.page-title {
    margin-block: 20px 10px;
}

.report-header {
    margin-top: 20Px;
    margin-bottom: 20px;
}

.tot-balance {
    font-size: 15px;
    font-weight: 700;
    margin-top: 2%;
    margin-right: 8%;
}

.settings-info-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: solid 1px #eee;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 12px;
    z-index: 999;
}

    .settings-info-header .header-text {
        margin-top: 5px;
    }

.position-fixed {
    position: fixed;
}

.group-configuration-modal .modal-content {
    width: 600px !important;
    margin: auto
}

.disable-link {
    pointer-events: none;
}

.active-selected {
    background: #3399ff !important;
    color: white !important;
}

.group-user ul li {
    list-style-type: none;
}

    .group-user ul li:hover {
        cursor: default !important;
        background: #c3c3eb;
    }

.disp-block {
    display: block !important;
}

.uploadpopupinfo {
    font-weight: normal;
    font-size: 14px;
    color: #373e4a;
}

.modal-content {
    font-size: 14px;
}

.support-link {
    white-space: nowrap;
    font-size: 15px;
}


.app-header-menu-item.hamburger-icon-wrapper {
    padding: 0;
    display: flex;
}
.hamburger-icon {
    padding: 15px;
}

.overlay-wrapper {
    padding: 10px 0;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
    background-color: #fff;
    min-width: 280px;
    z-index: 99;
}
.navigation-menu .menu-item {
    display: flex;
    color: #106ebe;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
}
.navigation-menu .menu-item.disabled {
    color: #c3c5c5;
    cursor: auto;
}
.navigation-menu .menu-item:hover:not(.head) {
    background-color: rgba(0,0,0,.05);    ;
}
.navigation-menu .menu-item:not(:last-child) {
    margin-bottom: 5px;
}
.navigation-menu .menu-item.head {
    padding: 0;
}
.navigation-menu .menu-item span {
    font-size: 16px;
    font-weight: 700;
    margin-left: 10px;
}
.navigation-menu .menu-item.head span {
    font-size: 20px;
    font-weight: 900;
}

[data-resource-id].disable , 
[data-resource-id].disable .magic-checkbox + label,
[data-resource-id].disable .magic-radio + label,
[data-resource-id].disable .custom-toggle-switch,
[data-resource-id].disable .custom-toggle-switch .custom-toggle-switch-input,
[data-resource-id].disable .custom-toggle-switch .custom-toggle-switch-input .custom-toggle-switch-label,
[data-resource-id].disable .custom-toggle-switch .custom-toggle-switch-input .custom-toggle-switch-label .custom-toggle-switch-handle,
[data-resource-id].disable .Select-control,
[data-resource-id].disable .Select-arrow-zone,
[data-resource-id].disable .link,
.disabled-rbac{
    opacity: 0.3 !important;
    pointer-events: auto !important;
    cursor: not-allowed !important;
}

/* STYLES FOR USER ROLE CHANGE POPUP */
/* STYLES OF BOOTSTRAP 5 MODAL */
.rolechange-notification-modal .modal-header {
    background-color: #fff;
    line-height: 18px;
    padding: 9px 16px;
    border-top-left-radius: 4.8px;
    border-top-right-radius: 4.8px;
}
.rolechange-notification-modal .modal-header h3 {
    font-family: "Mulish";
    font-weight: 700;
    color: #212529;
    font-size: 20px;
}
.user-autologout-modal-content {
    font-family: "Mulish"; 
}
.rolechange-notification-modal .modal-content {
    width: 500px !important;
    margin: auto;
    border-radius: 5px !important;
    border: 1px solid rgba(0,0,0,.2);
    font-size: 16px;
    box-shadow: 5px 5px 61px 0px;
}

.rolechange-notification-modal .modal-body {
    font-size: 14px;
    padding: 16px;
    color: #212529;
}

.rolechange-notification-modal .modal-footer {
    padding: 12px;
}

.rolechange-notification-modal .modal-footer .btn {
    border-radius: 2px;
    font-weight: 300;
    min-width: 77px;
    padding: 6px 12px;
    font-size: 16px;
    background-color: #0973ba;
    font-family: "Mulish"; 
    margin: 4px; 
}

.marB20 {
    margin-bottom: 20px;
}

.font14{
    font-size: 14px;
}

.font12{
    font-size: 12px;
}

.card-title i {
    color: #aaa;
    margin-right: 10px;
    text-shadow: #fff -2px 2px 1px;
}

.my-notification-help{
    margin-left: 5px;
    font-size: 12px;
}

.unlock-button {
    width: 100%;
}

.unlock-button i {
    padding-top: 8px !important;
}

.confirmation-popover {
    font-size: 12px;
}

.confirmation-popover h3 {
    font-size: 16px;
}

.header-action-buttons {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
}

/* Loader UI */

#divLoading {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    display: none;
    background-color: rgba(255, 255, 255, .8);
}

    @-webkit-keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

#divLoading::after {
    content: '';
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border: 3px solid #eee;
    border-width: 3px;
    border-radius: 50%;
    border-top-color: #07d !important;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

.loader-text {
    text-align: center;
    margin-top: 22%;
}

.my-login-history .modal-content {
    width: 900px !important;
    margin: auto
}

.nav-link.active {
    background-color: transparent !important;
}

.asterisk-error{
    color: #cc4a43;
}

.filter-dropdown-item-width{
    width: 250px;
}

.filter-dropdown-sub-item-width{
    width: 158px !important;
}

.filter-button-padding{
    padding: 6px 8px !important;
}

.filter-sub-button-padding{
    padding-left: 6px;
    padding-bottom: 3px;
}

.remove-cursor-pointer{
    cursor: 'text';
    pointer-events: none;
}

.dropdown-sub-menu{
    left: -160px !important;
}

.accessdenied-text-header {
    color: #212529;
    text-align: center;
    font-family: 'Mulish', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 40px;
}

.accessdenied-text-body {
    color: #212529;
    font-family: 'Mulish', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.btn.button-sign-in {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    display: flex;
    width: 220px;
    padding: 6px 12px !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px !important;
    border: 1px solid #0973BA !important;
    background: #0973BA !important;
    margin-top: 40px;
}

.accessdenied-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nameColumnClass {
    overflow: visible !important;
}

.nameColumnClass .nameCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameColumnClass .nameCell .linkIconContainer {
   display: flex;
   align-items: center;
   margin-top: -5px !important;
}

.nameColumnClass .nameCell .linkIconContainer button {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
    background-image: none !important;
    outline: none !important;
}

.nameColumnClass .nameCell .linkIconContainer button:active {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
    background-image: none !important;
    outline: none !important;
}

.nameColumnClass .nameCell .linkIconContainer button:focus {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
    background-image: none !important;
    outline: none !important;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-toggle::after {
    display: none;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu {
    translate: -50%;
    cursor: default;
}

.dropdown-menu.show[x-placement="top-start"] {
    margin-bottom: 5px !important;
}

.dropdown-menu.show[x-placement="top-start"]:after {
    content: url("src/assets/images/arrow-down.svg");
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    bottom: 2px;
}

.dropdown-menu.show[x-placement="bottom-start"] {
    margin-top: 4px !important;
}

.dropdown-menu.show[x-placement="bottom-start"]:before {
    content: url("src/assets/images/arrow-up.svg");
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    top: -10px;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer {
    padding: 0 1rem;
    width: 350px;
    max-width: 350px;
    min-height: 100px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: normal;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .connectedStyle {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-decoration: underline;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData {
    padding-top: 0rem;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkClientName {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    margin-top: 1rem;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkClientId {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #565a5e;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkEmailId {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkPrimaryUTE .emailId {
    color: #0973ba;
}

