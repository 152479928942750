.draggable-list-container .draggable-list-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25rem 0.75rem !important;
    font-size: 1.65rem;
    border-bottom: 1px solid #c4c6c8 !important;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}
.draggable-list-container .draggable-list-card:nth-child(even) {
    background-color: rgba(240, 241, 241, 0.5);
}
.draggable-list-container .draggable-list-card:hover {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}
.draggable-list-container .draggable-list-card:active {
    cursor: -webkit-grabbing !important;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing !important;
}
.draggable-list-container .grip-container svg {
    color: #05386b;
    margin-top: -3px;
}
.draggable-list-container .content-container {
    padding: 0 0.4rem;
    width: 94%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 24px;
}
.draggable-list-container .selected-card {
    background-color: rgba(9, 115, 186, .6) !important;
}
.disabled-card {
    cursor: not-allowed !important;
}
